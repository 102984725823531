import { render, staticRenderFns } from "./EmployeeDetails.vue?vue&type=template&id=6c16afda&scoped=true"
import script from "./EmployeeDetails.vue?vue&type=script&lang=js"
export * from "./EmployeeDetails.vue?vue&type=script&lang=js"
import style0 from "./EmployeeDetails.vue?vue&type=style&index=0&id=6c16afda&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c16afda",
  null
  
)

export default component.exports