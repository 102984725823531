<template>
  <div class="w-full h-full pt-2">
    <div class="flex">
      <div class=" pr-5">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          Employee
        </h1>
      </div>
      <div class="w-7/12 mt-4">
        <Breadcrumb
          @onClick="handleBreadcrumbClick($event)"
          :items="breadcrumbs"
        />
      </div>
    </div>
    <div style="height:100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else class="mx-3 pl-5 mt-2 mb-4 pb-10">
      <div class="flex">
        <div class="w-1/5">
          <div class="mb-6 mt-8">
            <img
              class="border border-gray-200 rounded"
              :src="employeeDetails.photo ? employeeDetails.photo : avater"
              alt="profile pic"
              style="height: 222px; width: 222px; border-radius: 5px"
            />
          </div>
          <p class="text-lg text-darkPurple font-bold mb-3">
            {{ $route.query.user }}
          </p>

          <div class=" flex flex-col">
            <span class="text-romanSilver my-2">Designation:</span>
            <span class="capitalize font-semibold mb-3 ">
              {{ employeeDetails.designation }}</span
            >
            <span class="text-romanSilver mb-2">Function:</span>
            <span class="capitalize font-semibold mb-3 ">{{
              employeeDetails.function
            }}</span>
            <span class="text-romanSilver mb-2">Phone:</span>
            <span class="capitalize font-semibold mb-3 ">{{
              employeeDetails.phoneNo
            }}</span>
            <span class="text-romanSilver mb-2">Email:</span>
            <span class=" break-words font-semibold mb-3 ">{{
              employeeDetails.email
            }}</span>
            <span class="text-romanSilver mb-2">Location:</span>
            <span class="capitalize font-semibold mb-3 ">{{
              employeeLocation
            }}</span>
            <span class="text-romanSilver mb-2">Engagement Date:</span>
            <span class="capitalize font-semibold mb-3 ">{{
              employeeDetails.dateAdded
                ? $DATEFORMAT(
                    new Date(employeeDetails.dateAdded),
                    "MMM dd, yyyy"
                  )
                : "-"
            }}</span>
          </div>
        </div>
        <div class="ml-6 w-4/5">
          <div class=" mt-6">
            <div class=" w-full">
              <p class=" font-bold text-lg mb-7 inline-block">
                Personal Info
              </p>
            </div>
            <div
              class="grid grid-cols-12 gap-3 text-darkPurple"
              style="margin-top:1.125rem"
            >
              <div class="col-span-3">
                <div class="text-sm mb-1 text-jet">
                  First Name
                </div>
                <div class="disabled wrapper disabled_hideOutline">
                  {{ employeeDetails.fname }}
                </div>
              </div>
              <div class="col-span-3">
                <div class="text-sm mb-1 text-jet">
                  Middle Name
                </div>
                <div class="disabled wrapper disabled_hideOutline">
                  {{ employeeDetails.mname }}
                </div>
              </div>
              <div class="col-span-3">
                <div class="text-sm mb-1 text-jet">
                  Last Name
                </div>
                <div class="disabled wrapper disabled_hideOutline">
                  {{ employeeDetails.lname }}
                </div>
              </div>
              <div class="col-span-3">
                <div class="text-sm mb-1 text-jet">
                  Gender
                </div>
                <div class="disabled wrapper disabled_hideOutline">
                  {{ employeeDetails.gender }}
                </div>
              </div>
              <div class="col-span-3">
                <div class="text-sm mb-1 text-jet">
                  Date of Birth
                </div>
                <div class="disabled wrapper disabled_hideOutline">
                  {{ employeeDetails.dob }}
                </div>
              </div>
              <div class="col-span-3">
                <div class="text-sm mb-1 text-jet">
                  Phone Number
                </div>
                <div class="disabled wrapper disabled_hideOutline">
                  {{ employeeDetails.phoneNo }}
                </div>
              </div>

              <div class="col-span-3">
                <div class="text-sm mb-1 text-jet">
                  Work Email
                </div>
                <div
                  v-tooltip.bottom-start="employeeDetails.email"
                  class="disabled wrapper disabled_hideOutline"
                >
                  {{ employeeDetails.email }}
                </div>
              </div>
            </div>
            <divider class="my-8" />
            <p class=" font-bold text-lg mb-7">
              Employment Info
            </p>
            <div
              class="grid grid-cols-12 gap-6 text-darkPurple"
              style="margin-top:1.125rem"
            >
              <div class="col-span-3">
                <div class="text-sm mb-1 text-jet">
                  Employment Type
                </div>
                <div class="disabled wrapper disabled_hideOutline">
                  {{ employeeDetails.employmentType }}
                </div>
              </div>
              <div class="col-span-3">
                <div class="text-sm mb-1 text-jet">
                  Designation
                </div>
                <div class="disabled wrapper disabled_hideOutline">
                  {{
                    employeeDetails.designation
                      ? employeeDetails.designation
                      : ""
                  }}
                </div>
              </div>
              <div class="col-span-3">
                <div class="text-sm mb-1 text-jet">
                  Job Level
                </div>
                <div class="disabled wrapper disabled_hideOutline">
                  {{ employeeDetails.jobLevel ? employeeDetails.jobLevel : "" }}
                </div>
              </div>
              <div class="col-span-3">
                <div class="text-sm mb-1 text-jet">
                  Function
                </div>
                <div class="disabled wrapper disabled_hideOutline">
                  {{ employeeDetails.function ? employeeDetails.function : "" }}
                </div>
              </div>
              <!-- <div class="col-span-3">
                <div class="text-sm mb-1 text-jet">
                  Reporting To
                </div>
                <div class="disabled wrapper disabled_hideOutline">
                  {{
                    employeeDetails.reportingTo
                      ? `${employeeDetails.reportingTo.fname} ${employeeDetails.reportingTo.lname}`
                      : ""
                  }}
                </div>
              </div> -->
              <!-- <div class="col-span-3">
                <div class="text-sm mb-1 text-jet">
                  Office Location
                </div>
                <div class="disabled wrapper disabled_hideOutline">
                  {{
                    employeeDetails.officeLocation
                      ? employeeDetails.officeLocation
                      : ""
                  }}
                </div>
              </div> -->
            </div>
            <divider class="my-8" />
            <p class=" font-bold text-lg mb-7">
              Reporting Line
            </p>
            <div
              class="text-darkPurple flex w-full"
              style="margin-top:1.125rem;"
            >
              <div class="flex" style="overflow-x: auto; width:100%">
                <div v-for="item in reportingLine" :key="item.id" class="flex ">
                  <div
                    class="flex p-2 bg-ghostWhite w-full"
                    style="box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06), 0px 1px 3px rgba(8, 7, 8, 0.1); width: 312px"
                  >
                    <div class="flex self-center">
                      <img
                        class=""
                        v-if="item.img"
                        alt="profile pic"
                        style="height: 42px; width: 42px; border-radius: 50%"
                      />
                      <div
                        style="height: 42px; width: 42px; border-radius: 50%;"
                        class="text-blueCrayola border text-center font-semibold pt-2"
                        v-else
                      >
                        {{ $getInitials(`${item.name}`) }}
                      </div>
                    </div>
                    <div class="w-full ml-3 capitalize ">
                      <p class="text-romanSilver font-semibold">
                        {{ item.name }}
                      </p>
                      <p class="text-xs uppercase">
                        {{ item.designation }}
                      </p>
                      <p class="text-xs uppercase">
                        {{ item.level }}
                      </p>
                    </div>

                    <span
                      class="w-2 h-2 ml-3 bg-flame rounded-full flex self-center"
                      style="width:3.6%"
                    />
                  </div>
                  <icon
                    class-name="text-romanSilver mx-3"
                    style="width: 4rem"
                    v-if="reportingLine[reportingLine.length - 1] !== item"
                    icon-name="arrow_line"
                  />
                </div>
              </div>
            </div>
            <divider class="my-8" />
            <p class="font-bold text-lg mb-7">
              Direct Reports
            </p>
            <sTable
              :headers="directReportHeader"
              :items="directReportData"
              style="width:100%"
              :loading="false"
              aria-label="employee table"
              class="w-full"
              has-number
            >
              <template v-slot:item="{ item }">
                <div v-if="item.photo" class="flex">
                  <img
                    class=""
                    :src="item.data.photo"
                    v-if="item.data.photo"
                    alt="profile pic"
                    style="height: 30px; width: 30px; border-radius: 5px"
                  />
                  <div
                    style="height: 30px; width: 30px; border-radius: 5px;"
                    class="text-blueCrayola border text-center font-semibold pt-2"
                    v-else
                  >
                    {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
                  </div>
                  <span
                    @click="handleDirectClick(item.data)"
                    class="font-bold cursor-pointer underline ml-2 flex self-center text-ceruleanBlue"
                    >{{ item.data.fname }} {{ item.data.lname }}</span
                  >
                </div>
                <span v-else-if="item.function" class="text-center">
                  {{ item.data.function }}
                </span>
                <span v-else-if="item.designation" class="text-center">
                  {{ item.data.designation }}
                </span>
                <span v-else-if="item.level" class="text-center">
                  {{ item.data.level }}
                </span>
              </template>
            </sTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import male from "@/assets/male-user.png";
import female from "@/assets/woman.png";
import unknown from "@/assets/unknown.png";

export default {
  components: {
    Breadcrumb,
    Divider: () => import("@/components/divider"),
    STable: () => import("@scelloo/cloudenly-ui/src/components/table")
  },
  name: "EssEmployeeDetails",
  data() {
    return {
      employeeDetails: null,
      male,
      female,
      unknown,
      user: null,
      loading: true,
      employeeLocation: "",

      reportingLine: [
        {
          id: 1,
          name: "John Doe",
          designation: "CEO",
          level: "Level 1",
          img: null
        },
        {
          id: 2,
          name: "Jane Doe",
          designation: "Director",
          level: "Level 2",
          img: null
        },
        {
          id: 3,
          name: "John Doe",
          designation: "Manager",
          level: "Level 3",
          img: null
        }
      ],
      directReportData: [
        {
          name: "John Doe",
          designation: "CEO",
          function: "CEO",
          level: "Level 1",
          photo: null
        },
        {
          name: "Jane Doe",
          designation: "Director",
          function: "Director",
          level: "Level 2",
          photo: null
        },
        {
          name: "John Doe",
          designation: "Manager",
          function: "Manager",
          level: "Level 3",
          photo: null
        }
      ],
      directReportHeader: [
        { title: "NAME", value: "photo" },
        { title: "Function", value: "function" },
        { title: "Designation", value: "designation" },
        { title: "Level", value: "level" }
      ]
    };
  },
  computed: {
    avater() {
      if (this.$route.query.gender === "Male") {
        return this.male;
      }
      if (this.$route.query.gender === "Female") {
        return this.female;
      }
      return this.unknown;
    },
    breadcrumbs() {
      return [
        {
          disabled: false,
          text: this.$route.params.backTo ? this.$route.params.backTo.name : "Directory",
          href: this.$route.params.backTo ? this.$route.params.backTo.href : "EssDirectiory",
          id: "Employee Directory"
        },
        {
          disabled: false,
          text: this.user || this.$route.query.user,
          id: "View Employee Profile"
        }
      ];
    }
  },
  watch: {
    $route() {
      this.user = this.$route.query.user;
    }
  },
  methods: {
    handleDirectClick(data) {
      this.loading = true;
      this.$router.push({
        name: "EssDirectioryDetails",
        params: { id: data.userId },
        query: {
          user: `${data.fname} ${data.lname}`,
          gender: data.gender
        }
      });
      this.getEmployeeDetails();
    },
    async handleClick(currentTab) {
      if (currentTab !== "Employee Groups") {
        this.currentTab = currentTab;
      } else {
        try {
          await this.$handlePrivilege("groups", "viewGroups");
          this.currentTab = currentTab;
        } catch (error) {
          this.$toasted.error(
            "You do not have permission to view this page please contact your admin",
            { duration: 5000 }
          );
        }
      }
    },
    handleBreadcrumbClick(event) {
      this.$router.push({
        name: event
      });
    },
    async getEmployeeDetails() {
      const { data } = await this.$_getEssDirectoryDetails(
        this.$route.params.id
      );
      this.employeeDetails = data.employeeInfo;
      this.directReportData = this.employeeDetails.directReports;
      this.reportingLine = this.employeeDetails.reportingLine
        ? this.employeeDetails.reportingLine
        : [];
      if (this.employeeDetails.location) {
        const findCountry = this.$store.state.countries.find(
          item => item.Alpha3Code === this.employeeDetails.location.country
        );
        const findState = this.$store.state.states.find(
          item => item.id === this.employeeDetails.location.state
        );
        if (this.employeeDetails.location) {
          if (this.employeeDetails.location.address) {
            this.employeeLocation += this.employeeDetails.location.address;
          }

          if (findState) {
            this.employeeDetails.location.state = findState.name;
            this.employeeLocation += ` ${this.employeeDetails.location.state}`;
          }
          if (findCountry) {
            this.employeeDetails.location.country = findCountry.Name;
            this.employeeLocation += ` ${this.employeeDetails.location.country}`;
          }
        }
      }
      this.loading = false;
    }
  },
  async mounted() {
    window.scrollTo(0, 0);
    this.getEmployeeDetails();
  }
};
</script>
<style scoped>
.wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
